<template>
  <div class="bg-white block-rounded text-center">
    <div class="row">
      <div class="col">
        <!-- <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in imgList" :key="index">
            <img :src="item.url" class="swiper-image" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper> -->
        <div class="bg" :style="'background-image: url('+baseImage+');'">
					<div class="subTitle">
						<h1>解决方案与案例</h1>
            <h5>以下仅为部分解决方案和案例，如有兴趣或想了解更多详情，请联系我们</h5>
					</div>
				</div>
        
      </div>
    </div>
    <div class="block-content">
      <h2>解决方案</h2>

      <p v-for="(item, inx) in solutionList" :key="inx + '-solution'">
        <a target="_blank" :href="item.url">{{ item.title }}</a>
      </p>
      <p>continue...</p>
      <h2>案例列表</h2>
      <p v-for="(item, no) in caseList" :key="no + '-case'">
        <a target="_blank" :href="item.url">{{ item.title }}</a>
      </p>
      <p>continue...</p>
      <h2>案例展示</h2>
      <p v-for="(item, index) in caseShowList" :key="index + '-solutionShow'">
        <a target="_blank" :href="item.url">{{ item.title }}</a>
      </p>
      <p>continue...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
      imgList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/1630162.jpg",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/1630162417.jpg",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/1630161.jpg",
        },
      ],
      baseImage: 'https://oss.cnwscada.com/officalWebsite/assets/image/productBg.png',
      solutionList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/platsolution.pdf",
          title: "解决方案1:工业系统物联网解决方案之分布式工业物联网平台",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/plcsolution.pdf",
          title: "解决方案2:手机远程监控解决方案（无人值守站）",
        },
      ],
      caseList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/caselist1.pdf",
          title: "实时监控系统案例列表",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/caselist2.pdf",
          title: "智能家居之三恒控制系统案例列表",
        },
      ],
      caseShowList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-csnz.pdf",
          title: "长沙南站高铁站",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-gz.pdf",
          title: "江西赣州城区市政亮化系统",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-jysh.pdf",
          title: "境元三恒系统-分布式",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-mc.pdf",
          title: "M-climate办公楼智能系统",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-shgx.pdf",
          title: "上海高校农产品冷链智能管理系统",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-shtp.pdf",
          title: "约克工程",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-villa.pdf",
          title: "私家别墅智能家居控制系统",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/link/case-water.pdf",
          title: "全国污水处理系统",
        },
      ],
    };
  },
  // computed: {
  //   swiper() {
  //     return this.$refs.mySwiper.swiper;
  //   },
  // },
  // mounted() {
  //   this.swiper.slideTo(3, 1000, false);
  // },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}
.title {
  font-size: 28px;
  line-height: 120%;
  padding-bottom: 1.5rem;
  padding-top: 2.5rem;
}

.swiper-image {

}

.bg {
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 5rem 0rem;
  text-align: left;
  color: #FFF;

}

.subTitle {
  padding-left: 0.5rem;
  color: #FFF;
}

.subTitle h1{
   padding-left: 1rem;
  color: #FFF;
}

.subTitle h5{
   padding-left: 1rem;
  color: #d3d3d3;
}

</style>
